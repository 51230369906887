import { EffectFilterCurrencyField, EffectFilterDateField, MeasureFieldNames, Sort } from "api-shared";
import { TFunction } from "i18next";
import { useMemo } from "react";
import SidebarSelect from "../../../components/sidebar/SidebarSelect";
import { OrderedOptionHandler, useOrderOptions } from "../../../hooks/useOrderOptions";
import { translationKeys } from "../../../translations/main-translations";

export interface DeskOrderSelectProps {
    value: string;
    order: Sort;
    translate: TFunction;
    onChange: OrderedOptionHandler<string>;
}

const orderByOptions = {
    [MeasureFieldNames.LastModificationAt]: translationKeys.VDLANG_DESK_SORT_BY_LAST_MODIFICATION_AT,
    [EffectFilterCurrencyField.Effect]: translationKeys.VDLANG_DESK_SORT_BY_EFFECT,
    [EffectFilterDateField.StartDate]: translationKeys.VDLANG_DESK_SORT_BY_EFFECTIVENESS_START,
    [MeasureFieldNames.CreatedAt]: translationKeys.VDLANG_DESK_SORT_BY_CREATED_AT,
};

function DeskOrderSelect({ translate, value, order, onChange, ...otherProps }: Readonly<DeskOrderSelectProps>) {
    const options = useMemo(
        () =>
            Object.entries(orderByOptions).map(([value, label]) => ({
                value,
                label: translate(label),
            })),
        [translate],
    );

    const orderOptions = useOrderOptions(value, order, options, onChange);

    return <SidebarSelect label={translate(translationKeys.VDLANG_DESK_SORT_BY)} {...orderOptions} {...otherProps} />;
}

export default DeskOrderSelect;
