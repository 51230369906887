import { Button, Grid } from "@mui/material";
import { FieldDefinitionsDto, MeasureFieldNames } from "api-shared";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OverlineLabel from "../../../components/OverlineLabel";
import TableColumnConfigDialog from "../../../components/table/TableColumnConfigDialog";
import useDialog from "../../../hooks/useDialog";

interface IColumnConfigurationProps {
    columns: string[];
    updateColumns: (newColumns: string[]) => void;
    fieldDefinitions: FieldDefinitionsDto;
    className?: string;
}

const ColumnConfiguration = ({ columns: columnsProps, updateColumns, fieldDefinitions, className }: IColumnConfigurationProps) => {
    const dialog = useDialog();
    const [columns, setColumns] = useState(columnsProps);

    const { t: translate } = useTranslation();

    useEffect(() => {
        setColumns(columnsProps);
    }, [columnsProps]);

    const saveColumns = () => updateColumns(columns);

    return (
        <Grid container className={className}>
            <TableColumnConfigDialog
                open={dialog.isOpen}
                onClose={dialog.close}
                columns={columns}
                disabledColumns={[MeasureFieldNames.Title]}
                updateColumns={setColumns}
                onSave={saveColumns}
                translate={translate}
                fieldDefinitions={fieldDefinitions}
            />
            <OverlineLabel>{translate("columns")}</OverlineLabel>
            <Button fullWidth onClick={dialog.open}>
                {translate("change_columns")}
            </Button>
        </Grid>
    );
};
export default ColumnConfiguration;
