import { Chip, chipClasses, styled } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import withTooltip from "../../../hocs/withTooltip";

const DeskTileLabel = styled(Chip)(({ theme }) => ({
    maxWidth: 248,
    height: "auto",
    fontSize: theme.typography.caption.fontSize,
    backgroundColor: blueGrey[50],
    borderRadius: theme.shape.borderRadius,
    cursor: "inherit",
    [` .${chipClasses.label}`]: {
        padding: theme.spacing(0.125, 1),
    },
}));

export default withTooltip(DeskTileLabel);
