import AddIcon from "@mui/icons-material/AddRounded";
import { Box, Button, Divider, DividerProps, ListItemText, MenuItem, Skeleton, styled } from "@mui/material";
import { DEFAULT_FILTER_ID } from "api-shared";
import { omit, times } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BannerLayout from "../../components/BannerLayout";
import DangerMenuItem from "../../components/DangerMenuItem";
import SplitButton from "../../components/SplitButton";
import Tooltip from "../../components/Tooltip";
import DeleteDialog from "../../components/dialogues/DeleteDialog";
import {
    useCreateSearchConfigMutation,
    useDeleteSearchConfigMutation,
    useSearchConfigsQuery,
    useUpdateSearchConfigMutation,
} from "../../domain/search-config";
import { useUiState } from "../../domain/ui-state";
import { useCurrentUserId } from "../../domain/users";
import useDialog from "../../hooks/useDialog";
import useSearchConfig from "../../hooks/useSearchConfig";
import { translationKeys } from "../../translations/main-translations";
import DnDSearchConfigList from "./DnDSearchConfigList";
import Measures from "./Measures";
import SearchConfigDialog from "./SearchConfigDialog";
import SearchConfigItem from "./SearchConfigItem";

const Header = styled("div")(({ theme }) => ({
    padding: theme.spacing(1, 3),
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(),
    borderBottom: `1px solid ${theme.palette.divider}`,
}));

const VerticalDivider = (props: DividerProps) => <Divider orientation="vertical" flexItem {...props} />;

const ResetButton = styled(Button)(({ theme }) => ({
    padding: theme.spacing(0.875, 1.375),
    fontSize: theme.typography.pxToRem(13),
    lineHeight: "1rem",
    color: theme.palette.text.primary,
    borderColor: theme.palette.border.main,
    flexShrink: 0,
}));

const AddButton = styled(Button)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(13),
}));

const AddButtonContainer = styled("div")(({ theme }) => ({
    flexGrow: 1,
    flexShrink: 0,
}));

// The Standard search config item on the left is ~80px wide, use that as a default for all those skeletons
const SearchConfigItemSkeleton = () => <Skeleton sx={{ mx: 1.5 }} width={56} />;

enum Actions {
    Save,
    Rename,
    Remove,
}

const ActionLabels: Record<Actions, translationKeys> = {
    [Actions.Save]: translationKeys.VDLANG_SAVE_VIEW,
    [Actions.Rename]: translationKeys.VDLANG_RENAME_VIEW,
    [Actions.Remove]: translationKeys.VDLANG_SEARCH_CONFIGS_REMOVE,
};

const SearchConfigProvider = () => {
    const currentUserId = useCurrentUserId();
    const searchConfigsQuery = useSearchConfigsQuery();
    const searchConfigs = searchConfigsQuery.data;

    const dialog = useDialog();
    const [isRenamingActive, setIsRenamingActive] = useState(false);

    const [uiState, updateUiState] = useUiState();
    const [lastAddedSearchConfig, setLastAddedSearchConfig] = useState<number | null>(null);

    const createSearchConfigMutation = useCreateSearchConfigMutation({
        onSuccess: (input) => setLastAddedSearchConfig(input.id),
    });
    const updateSearchConfigMutation = useUpdateSearchConfigMutation();
    const deleteSearchConfigMutation = useDeleteSearchConfigMutation();

    const [searchConfigToDelete, setSearchConfigToDelete] = useState<number>();

    const { t: translate } = useTranslation();
    const { activeSearchConfig, standardSearchConfig, updateSearchConfig, hasUnsavedChanges, resetUnsavedChanges, setActiveConfigId } =
        useSearchConfig({
            searchConfigs,
            fallbackSearchConfigId: uiState.lastSearchConfigId,
            updateSearchConfig: updateSearchConfigMutation.mutate,
        });
    const deleteSearchConfig = () => {
        if (!standardSearchConfig || !searchConfigToDelete) {
            return;
        }
        setActiveConfigId(standardSearchConfig.id);
        updateUiState({ lastSearchConfigId: standardSearchConfig.id });
        deleteSearchConfigMutation.mutate(searchConfigToDelete);
    };

    const handleSave = () => {
        if (activeSearchConfig === undefined) {
            // This should not happen, as save cannot be triggered while search configs are not initialized yet
            return;
        }
        // make sure the filterId is always valid when sending searchConfig to backend
        updateSearchConfigMutation.mutate({ ...activeSearchConfig, filterId: activeSearchConfig.filterId ?? DEFAULT_FILTER_ID });
    };

    const handleSwitchView = useCallback(
        (id: number) => {
            updateUiState({ lastSearchConfigId: id });
            setActiveConfigId(id);
        },
        [setActiveConfigId, updateUiState],
    );
    useEffect(() => {
        if (lastAddedSearchConfig !== null) {
            handleSwitchView(lastAddedSearchConfig);
            setLastAddedSearchConfig(null);
        }
    }, [lastAddedSearchConfig, handleSwitchView]);
    const handleResetView = () => {
        resetUnsavedChanges();
    };

    const handleOrderChange = useCallback((newOrder: number[]) => updateUiState({ searchConfigOrder: newOrder }), [updateUiState]);

    const isAllowedToEdit = activeSearchConfig?.userId === currentUserId && activeSearchConfig?.clientId === null;

    const saveSearchConfig = ({ id, name }: { id?: number; name: string }) => {
        if (activeSearchConfig === undefined) {
            // cannot save, when active searchConfig cannot be resolved, neither update nor create are possible then
            return;
        }
        if (id === undefined) {
            createSearchConfigMutation.mutate({ ...omit(activeSearchConfig, "id"), name });
        } else {
            updateSearchConfigMutation.mutate({ ...activeSearchConfig, name });
        }
    };

    const handleAddSearchConfig = () => {
        setIsRenamingActive(false);
        dialog.openDialog();
    };

    const handleRenameSearchConfig = () => {
        setIsRenamingActive(true);
        dialog.openDialog();
    };

    const splitButtonActions = [
        <MenuItem divider={activeSearchConfig?.isDefault === false} key={Actions.Rename} sx={{ py: 1 }} onClick={handleRenameSearchConfig}>
            <ListItemText primary={translate(ActionLabels[Actions.Rename])} />
        </MenuItem>,
        <DangerMenuItem
            key={Actions.Remove}
            sx={{ py: 1 }}
            onClick={() => activeSearchConfig !== undefined && setSearchConfigToDelete(activeSearchConfig.id)}
        >
            <ListItemText primary={translate(ActionLabels[Actions.Remove])} />
        </DangerMenuItem>,
    ];

    return (
        <BannerLayout
            banner={
                <Header>
                    <DeleteDialog
                        open={searchConfigToDelete !== undefined}
                        onClose={() => setSearchConfigToDelete(undefined)}
                        translate={translate}
                        onDelete={deleteSearchConfig}
                        hideDescription
                        title={translate(translationKeys.VDLANG_SEARCH_CONFIGS_REMOVE_TITLE, {
                            name: searchConfigs?.find(({ id }) => searchConfigToDelete === id)?.name ?? "",
                        })}
                    />
                    <SearchConfigDialog
                        open={dialog.isOpen}
                        onClose={dialog.closeDialog}
                        translate={translate}
                        onSave={saveSearchConfig}
                        searchConfig={isRenamingActive ? activeSearchConfig : undefined}
                    />
                    {standardSearchConfig !== undefined ? (
                        <SearchConfigItem
                            id={standardSearchConfig.id}
                            name={translate(translationKeys.VDLANG_SEARCH_CONFIGS_STANDARD_NAME)}
                            selected={activeSearchConfig?.id === standardSearchConfig.id}
                            onClick={(e) => handleSwitchView(standardSearchConfig.id)}
                        />
                    ) : (
                        <SearchConfigItemSkeleton key="standard" />
                    )}
                    {searchConfigs === undefined ? (
                        <>
                            <VerticalDivider />
                            {times(3, (i) => (
                                <SearchConfigItemSkeleton key={i} />
                            ))}
                        </>
                    ) : null}
                    {searchConfigs?.some(({ isDefault }) => !isDefault) ? (
                        <>
                            <VerticalDivider />
                            <DnDSearchConfigList
                                searchConfigs={searchConfigs}
                                selectedId={activeSearchConfig?.id !== standardSearchConfig?.id ? activeSearchConfig?.id : undefined}
                                order={uiState.searchConfigOrder}
                                onSearchConfigSelect={handleSwitchView}
                                onOrderUpdate={handleOrderChange}
                            />
                        </>
                    ) : null}
                    <VerticalDivider />
                    <AddButtonContainer>
                        <AddButton startIcon={<AddIcon />} variant="text" color="primary" size="small" onClick={handleAddSearchConfig}>
                            {translate(translationKeys.VDLANG_SEARCH_CONFIGS_ADD)}
                        </AddButton>
                    </AddButtonContainer>
                    {activeSearchConfig?.isDefault === false && hasUnsavedChanges ? (
                        <ResetButton size="small" color="inherit" onClick={handleResetView}>
                            {translate(translationKeys.VDLANG_RESET)}
                        </ResetButton>
                    ) : null}
                    {activeSearchConfig?.isDefault === false ? (
                        <Tooltip
                            title={
                                !isAllowedToEdit ? translate(translationKeys.VDLANG_SEARCH_CONFIGS_COMPANY_WIDE_EDITING_DISABLED_HINT) : ""
                            }
                        >
                            <Box flexShrink={0}>
                                <SplitButton
                                    disabledButton={!hasUnsavedChanges || !isAllowedToEdit}
                                    disabledActions={!isAllowedToEdit}
                                    onClick={handleSave}
                                    label={translate(ActionLabels[Actions.Save])}
                                    actions={splitButtonActions}
                                />
                            </Box>
                        </Tooltip>
                    ) : null}
                </Header>
            }
        >
            <Measures searchConfig={activeSearchConfig} updateSearchConfig={updateSearchConfig} />
        </BannerLayout>
    );
};

export default SearchConfigProvider;
