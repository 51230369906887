import { Skeleton } from "@mui/material";
import { SearchViewType } from "api-shared";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import SidebarLayout from "../../components/SidebarLayout";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import { SearchConfig } from "../../domain/search-config";
import { useTrackedSearch } from "../../domain/tracking";
import useTabActions from "../../hooks/useTabActions";
import { useFieldDataSubscription } from "../../lib/field-options";
import { translationKeys } from "../../translations/main-translations";
import Desk from "./Desk";
import MeasureGrid from "./Grid";
import MeasuresPreferences from "./preferences/MeasuresPreferences";

const CollapsedHeaderLabels: Record<SearchViewType, translationKeys> = {
    [SearchViewType.DESK]: translationKeys.VDLANG_SECTIONS_PROCESSES_DESK,
    [SearchViewType.GRID]: translationKeys.VDLANG_SECTIONS_PROCESSES_GRID,
};

interface IUseSearchKeyProps {
    viewType: SearchViewType;
    trackSearch: (key: string, origin: "desk" | "grid") => void;
    initial?: string;
}
const useSearchKey = ({ trackSearch, viewType, initial = "" }: IUseSearchKeyProps) => {
    const [searchKey, setSearchKey] = useState(initial);
    const updateSearchKey = (searchKey: string) => {
        const origin = viewType === SearchViewType.DESK ? "desk" : "grid";
        trackSearch(searchKey, origin);
        setSearchKey(searchKey);
    };
    return [searchKey, updateSearchKey] as const;
};

interface IMeasuresProps {
    searchConfig?: SearchConfig;
    updateSearchConfig: (searchConfig: Partial<SearchConfig>) => void;
}

const Measures = ({ searchConfig, updateSearchConfig }: IMeasuresProps) => {
    const { t: translate } = useTranslation();
    const trackSearch = useTrackedSearch();

    // Field data from redux store is used in Grid, ScopeSelect and FilterConfiguration components
    useFieldDataSubscription();

    const [searchKey, updateSearchKey] = useSearchKey({ trackSearch, viewType: searchConfig?.viewType ?? SearchViewType.DESK });

    // trigger updating the ViewSelect tab indicator when sidebar has finished opening
    const { setActions, updateIndicator } = useTabActions();

    return (
        <SidebarLayout
            sidebar={
                <MeasuresPreferences
                    searchConfig={searchConfig}
                    updateSearchConfig={updateSearchConfig}
                    updateSearchKey={updateSearchKey}
                    searchKey={searchKey}
                    action={setActions}
                />
            }
            collapsedHeader={searchConfig !== undefined ? translate(CollapsedHeaderLabels[searchConfig.viewType]) : <Skeleton />}
            onOpened={updateIndicator}
        >
            {searchConfig !== undefined ? (
                <Routes>
                    <Route
                        path="grid"
                        element={<MeasureGrid searchConfig={searchConfig} updateSearchConfig={updateSearchConfig} searchKey={searchKey} />}
                    />
                    <Route path="desk" element={<Desk searchConfig={searchConfig} searchKey={searchKey} />} />
                </Routes>
            ) : (
                <LoadingAnimation />
            )}
        </SidebarLayout>
    );
};

export default React.memo(Measures);
