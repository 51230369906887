import { AdditionalInformation, AttributeTable, MeasureAttributeDto } from "api-shared";
import { TFunction } from "i18next";
import { createSelectHandler } from "../../../components/input/select/Select";
import SidebarSelect from "../../../components/sidebar/SidebarSelect";
import { useMeasureAttributes } from "../../../domain/endpoint";
import { translationKeys } from "../../../translations/main-translations";

interface AdditionalInformationSelectProps {
    value: string;
    onChange: (value: AdditionalInformation | null) => void;
    translate: TFunction;
}

const getLabelKey = (measureAttributes: MeasureAttributeDto[], tableName: string): string => {
    return measureAttributes.filter((attribute) => attribute.tableName === tableName)[0]?.title;
};

function AdditionalInformationSelect({ value, onChange, translate, ...selectProps }: Readonly<AdditionalInformationSelectProps>) {
    const measureAttributes = useMeasureAttributes();
    const OPTIONS = [
        {
            label: getLabelKey(measureAttributes, AttributeTable.Projects),
            value: AdditionalInformation.TAGS,
        },
        {
            label: getLabelKey(measureAttributes, AttributeTable.TreeNodes),
            value: AdditionalInformation.PRODUCT_GROUP,
        },
        {
            label: getLabelKey(measureAttributes, AttributeTable.CostLevers),
            value: AdditionalInformation.METHOD,
        },
        {
            label: getLabelKey(measureAttributes, AttributeTable.Companies),
            value: AdditionalInformation.COMPANIES,
        },
        {
            label: "no_additional_information",
            value: AdditionalInformation.NONE,
        },
    ];

    const translatedOptions = OPTIONS.map(({ label, value }) => ({
        value,
        label: translate(label),
    }));
    const selectedOption = translatedOptions.find((option) => option.value === value);

    return (
        <SidebarSelect
            isMulti={false}
            label={translate(translationKeys.VDLANG_ADDITIONAL_INFORMATION)}
            value={selectedOption}
            options={translatedOptions}
            onChange={createSelectHandler(onChange)}
            {...selectProps}
        />
    );
}

export default AdditionalInformationSelect;
