import { useQuery } from "@tanstack/react-query";
import { FilterDefinition, MeasureConversionRateDto, ScopeDto } from "api-shared";
import { ApiRequestOptions, apiPost } from "../../lib/api";

const MEASURES_CONVERSION_RATES_PATH = "measures/conversion";

function getConversionRates({ measureConfigId, filter, scope }: IMeasureConversionRateQueryOptions, options: ApiRequestOptions) {
    const data = {
        measureConfigId,
        filter,
        scope,
    };
    return apiPost<MeasureConversionRateDto>(MEASURES_CONVERSION_RATES_PATH, data, options);
}

interface IMeasureConversionRateQueryOptions {
    measureConfigId: number;
    filter: FilterDefinition | undefined;
    scope: ScopeDto;
}

export const useMeasureConversionRates = ({ measureConfigId, filter, scope }: IMeasureConversionRateQueryOptions) => {
    return useQuery({
        queryKey: [MEASURES_CONVERSION_RATES_PATH, { measureConfigId, filter, scope }] as const,
        queryFn: ({ queryKey, signal }) => getConversionRates(queryKey[1], { signal }),
        enabled: filter != null,
    });
};
