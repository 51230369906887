import { MeasureConfigDto } from "api-shared";
import { TFunction } from "i18next";
import { OnChangeValue } from "react-select";
import { Option } from "../../../components/input/select/types";
import SidebarSelect from "../../../components/sidebar/SidebarSelect";

interface IMeasureConfigSelectProps {
    translate: TFunction;
    updateValues: (values: number | number[]) => void;
    measureConfigs: MeasureConfigDto[];
    isMulti: boolean;
    values: number | number[];
}

const MeasureConfigSelect = ({ values, isMulti, measureConfigs, updateValues, translate }: IMeasureConfigSelectProps) => {
    const handleChange = (newValues: OnChangeValue<Option<number>, boolean>) => {
        if (newValues == null || (Array.isArray(newValues) && newValues.length === 0)) {
            // Block removing last element
            // isClearable flag is not sufficient, there are still rare events with no newValues
            return;
        }
        const sanitizedValue = "value" in newValues ? newValues.value : newValues.map(({ value }: Option<number>) => value);
        updateValues(sanitizedValue);
    };

    const options = measureConfigs.map((config) => ({
        label: translate(config.name),
        value: config.id,
        // prevent removal of last element
        isFixed: Array.isArray(values) && values.length <= 1,
    }));

    const allValues = Array.isArray(values) ? values : [values];
    const sanitizedValues = isMulti ? allValues : allValues.slice(0, 1);
    const selectedOptions = options.filter((option) => sanitizedValues.includes(option.value));

    return (
        <SidebarSelect
            value={isMulti ? selectedOptions : (selectedOptions[0] ?? null)}
            options={options}
            onChange={handleChange}
            isMulti={isMulti}
            label={isMulti ? translate("display_many") : translate("display")}
            isClearable={false}
        />
    );
};

export default MeasureConfigSelect;
