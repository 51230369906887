import { Tab, TabsActions } from "@mui/material";
import MuiTabs, { TabsProps as MuiTabsProps } from "@mui/material/Tabs";
import { SearchViewType } from "api-shared";
import React from "react";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../translations/main-translations";

const SearchViewTypeLabels = {
    [SearchViewType.DESK]: translationKeys.VDLANG_SECTIONS_PROCESSES_DESK,
    [SearchViewType.GRID]: translationKeys.VDLANG_SECTIONS_PROCESSES_GRID,
};

// https://github.com/mui/material-ui/pull/38717
const Tabs = React.forwardRef<HTMLDivElement, MuiTabsProps>((props, ref) => {
    return <MuiTabs ref={ref} {...props} />;
});

interface IViewSelectProps {
    viewType?: SearchViewType;
    action?: React.Ref<TabsActions>;
    updateView?: (newView: SearchViewType) => void;
    className?: string;
    disabled?: boolean;
}

const ViewSelect = ({ className, viewType, updateView, action, disabled }: IViewSelectProps) => {
    const { t: translate } = useTranslation();

    return (
        <Tabs
            className={className}
            value={viewType ?? false}
            onChange={updateView !== undefined ? (e, newValue) => updateView(newValue) : undefined}
            variant="fullWidth"
            action={action}
            component="div"
        >
            {Object.entries(SearchViewTypeLabels).map(([type, labelKey]) => (
                <Tab
                    key={type}
                    value={+type} // need to convert string to number
                    label={translate(labelKey)}
                    disabled={disabled}
                />
            ))}
        </Tabs>
    );
};
export default ViewSelect;
