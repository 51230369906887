import React, { useCallback, useState } from "react";

function useOverlapState(containerRef: React.RefObject<HTMLElement>, padding = 0) {
    const [hasTopOverlap, setHasTopOverlap] = useState(false);
    const [hasBottomOverlap, setHasBottomOverlap] = useState(false);

    const updateOverlap = useCallback(() => {
        if (containerRef.current === null) {
            return;
        }

        const { offsetHeight, scrollHeight, scrollTop } = containerRef.current;

        const isHeaderOverlappingList = scrollTop > padding;
        if (isHeaderOverlappingList !== hasTopOverlap) {
            setHasTopOverlap(isHeaderOverlappingList);
        }

        const isFooterOverlappingList = scrollHeight - scrollTop > offsetHeight + padding;
        if (isFooterOverlappingList !== hasBottomOverlap) {
            setHasBottomOverlap(isFooterOverlappingList);
        }
    }, [hasTopOverlap, hasBottomOverlap, padding, containerRef]);

    return { hasTopOverlap, hasBottomOverlap, updateOverlap };
}

export default useOverlapState;
