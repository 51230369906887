import { Box, Paper, Stack, styled, Typography } from "@mui/material";
import {
    AdditionalInformation,
    AttributeTable,
    EffectFilterCurrencyField,
    FilteredMeasureDto,
    MeasureAttributeDto,
    MeasureStatus,
    UserDto,
} from "api-shared";
import { TFunction } from "i18next";
import { isNumber } from "lodash";
import React, { useMemo } from "react";
import Dotdotdot from "react-dotdotdot";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FavoriteIcon from "../../../components/icons/FavoriteIcon";
import UnseenChangesIcon from "../../../components/icons/UnseenChangesIcon";
import MeasureIdChip from "../../../components/MeasureIdChip";
import Tooltip from "../../../components/Tooltip";
import UserEntryWithPopup from "../../../components/user/UserEntryWithPopup";
import { useCompanyNames, useMeasureAttributes, useProjectNames } from "../../../domain/endpoint";
import { useProcessName } from "../../../domain/measure-config";
import { useToggleFavoriteMeasure } from "../../../domain/measure/favorite";
import { useCostLeverNames } from "../../../domain/methods/cost-lever";
import { IndexedTreeNodePaths, useIndexedTreeNodePaths } from "../../../domain/tree-node";
import useCurrency from "../../../hooks/useCurrency";
import { useLanguage } from "../../../hooks/useLanguage";
import { AppState } from "../../../infrastructure/store";
import { calculateDaysUntilEffectiveness } from "../../../lib/measure";
import { RouteFor } from "../../../lib/routes";
import { translationKeys } from "../../../translations/main-translations";
import PreviewPartialEffect from "../PreviewPartialEffect";
import ProcessPreviewButton from "../ProcessPreviewButton";
import DeskTileLabel from "./DeskTileLabel";

const previewButtonClass = "ProcessPreviewButtonHover";

const RootPaper = styled(Paper, { shouldForwardProp: (name) => name !== "isDiscarded" })<{ isDiscarded: boolean }>(
    ({ theme, isDiscarded }) => ({
        width: 288,
        overflow: "hidden",
        position: "relative", // anchor for absolutely positioned preview button, unseenChanges indicator and effectivenessBorder
        ...(isDiscarded && { opacity: 0.5 }),
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
        [`&:not(:hover) .${previewButtonClass}`]: {
            display: "none",
        },
    }),
);

const MeasureTitle = styled("div")({
    maxWidth: 198,
    overflowWrap: "anywhere",
});

const PreviewButton = styled(ProcessPreviewButton)(({ theme }) => ({
    position: "absolute",
    top: theme.spacing(),
    right: theme.spacing(),
}));

const PositionedUnseennChangesIcon = styled(UnseenChangesIcon)(({ theme }) => ({
    position: "absolute",
    top: 17, // vertically center on first line of title
    left: theme.spacing(1.5),
}));

const StyledFavoriteIcon = styled(FavoriteIcon)(({ theme }) => ({
    padding: theme.spacing(0.25), // even small size of IconButton is too large here
}));

const DisguisedLink = styled(Link)(({ theme }) => ({
    // hide default link styles which would be applied an all text on the tile
    textDecoration: "none",
    color: theme.palette.text.primary,
}));

const DaysUntilEffectivenessIndicator = styled("div", { shouldForwardProp: (name) => name !== "daysUntilEffectiveness" })<{
    daysUntilEffectiveness: number;
}>(({ theme, daysUntilEffectiveness }) => ({
    position: "absolute",
    width: theme.spacing(0.5),
    height: "100%",
    ...(daysUntilEffectiveness > 14 && { backgroundColor: theme.palette.natureGreen.main }),
    ...(daysUntilEffectiveness <= 14 && { backgroundColor: theme.palette.secondary.main }),
    ...(daysUntilEffectiveness < 0 && { backgroundColor: theme.palette.error.main }),
}));

const transformTreeNodes = (indexedTreeNodes: IndexedTreeNodePaths, language: string): Record<number, string> => {
    return Object.fromEntries(
        Object.entries(indexedTreeNodes).map(([key, value]) => {
            return [key, language === "de" ? value.nameDe : value.nameEn];
        }),
    );
};

const useLabelData = (type: AdditionalInformation) => {
    const language = useLanguage();
    const costLeverNames = useCostLeverNames();
    const projectNames = useProjectNames();
    const companyNames = useCompanyNames();

    const TreeNodesById = useIndexedTreeNodePaths();
    const transformedTreeNodes = useMemo(() => {
        return transformTreeNodes(TreeNodesById, language);
    }, [TreeNodesById, language]);

    return useSelector((state: AppState) => {
        switch (type) {
            case AdditionalInformation.TAGS:
                return projectNames;
            case AdditionalInformation.METHOD:
                return costLeverNames;
            case AdditionalInformation.PRODUCT_GROUP:
                return transformedTreeNodes;
            case AdditionalInformation.COMPANIES:
                return companyNames;
            case AdditionalInformation.NONE:
            default:
                return {};
        }
    });
};

const getLabelKey = (measureAttributes: MeasureAttributeDto[], tableName: string): string | undefined => {
    return measureAttributes.filter((attribute) => attribute.tableName === tableName)[0]?.title;
};

function getLabelValue(measure: FilteredMeasureDto, type: AdditionalInformation, measureAttributes: MeasureAttributeDto[]): number[] {
    let value: number[] | number | null | undefined;
    let labelKey: string | undefined;
    switch (type) {
        case AdditionalInformation.TAGS:
            labelKey = getLabelKey(measureAttributes, AttributeTable.Projects);
            break;
        case AdditionalInformation.METHOD:
            labelKey = getLabelKey(measureAttributes, AttributeTable.CostLevers);
            break;
        case AdditionalInformation.PRODUCT_GROUP:
            labelKey = getLabelKey(measureAttributes, AttributeTable.TreeNodes);
            break;
        case AdditionalInformation.COMPANIES:
            labelKey = getLabelKey(measureAttributes, AttributeTable.Companies);
            break;
        case AdditionalInformation.NONE:
        default:
            value = [];
    }
    value = labelKey ? (measure.fields[labelKey] as number | number[] | undefined) : undefined;
    if (Array.isArray(value)) {
        return value;
    }
    return value != null ? [value] : [];
}

interface MeasureTileProps {
    measure: FilteredMeasureDto;
    additionalInformation?: AdditionalInformation;
    users: UserDto[];
    className?: string;
    translate: TFunction;
}

function MeasureTile({
    measure,
    additionalInformation = AdditionalInformation.NONE,
    users,
    className,
    translate,
}: Readonly<MeasureTileProps>) {
    const measureAttributes = useMeasureAttributes();

    const toggleFavoriteMeasure = useToggleFavoriteMeasure(measure.id, measure.isFavorite, measure.currentGateTaskConfigId);

    const { formatCurrency } = useCurrency();

    const daysUntilEffectiveness = calculateDaysUntilEffectiveness(measure);
    const assignedToUser = users ? users.find((u) => u.id === measure.assignedToId) : null;
    const isDiscarded = measure.status === MeasureStatus.STATUS_DISCARDED;

    const labelData = useLabelData(additionalInformation);
    const processName = useProcessName(measure);
    const labelValues = getLabelValue(measure, additionalInformation, measureAttributes);
    const labels = labelValues.map((value) => labelData[value]).filter((label) => label !== undefined);

    const effect = measure.calculatedFields[EffectFilterCurrencyField.Effect];
    const effectNoScope = measure.calculatedFields[`${EffectFilterCurrencyField.Effect}NoScope`];

    return (
        <Tooltip title={isDiscarded ? translate("Process discarded", { processName: translate(processName) }) : ""}>
            <RootPaper variant="elevation" isDiscarded={isDiscarded} className={className} elevation={2}>
                <PreviewButton measure={measure} className={previewButtonClass} />
                {measure.calculatedFields.unseenChanges ? (
                    <PositionedUnseennChangesIcon title={translate("new_indicator_desk_hint")} />
                ) : null}
                {measure.status !== MeasureStatus.STATUS_CLOSED && daysUntilEffectiveness !== null ? (
                    <Tooltip
                        title={translate(translationKeys.VDLANG_DESK_TILE_DAYS_UNTIL_EFFECTIVENESS_TOOLTIP, {
                            count: Math.abs(daysUntilEffectiveness),
                            context: daysUntilEffectiveness < 0 ? "negative" : undefined,
                        })}
                    >
                        <DaysUntilEffectivenessIndicator daysUntilEffectiveness={daysUntilEffectiveness} />
                    </Tooltip>
                ) : null}
                <DisguisedLink to={RouteFor.measure.forId(measure.id)}>
                    <Box sx={{ p: 1.5, pl: 3 }}>
                        <Stack justifyContent="space-between" spacing={1}>
                            <Stack direction="row" justifyContent="space-between">
                                <Tooltip title={measure.title}>
                                    <Typography variant="body2" component={MeasureTitle}>
                                        <Dotdotdot clamp={2}>{measure.title}</Dotdotdot>
                                    </Typography>
                                </Tooltip>
                                <MeasureIdChip measureId={measure.clientIid} />
                            </Stack>
                            {labels.length > 0 && (
                                <Box display="flex" gap={0.5} flexWrap="wrap">
                                    {labels.map((label) => (
                                        <DeskTileLabel key={label} title={label} label={label} />
                                    ))}
                                </Box>
                            )}
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                                sx={{ pt: 2 }} // use padding here because parent stack overrides any margin
                                onClick={
                                    // Avoid click events inside of popover content bubbling up to Link component of the DeskTile,
                                    // which would trigger navigation to the process
                                    // Event bubbling through portals is a feature of react to hide the abstraction whether a portal
                                    // is used or not: https://reactjs.org/docs/portals.html#event-bubbling-through-portals
                                    (e) => e.stopPropagation()
                                }
                            >
                                <Typography variant="body2" color="textSecondary" component="div" sx={{ flexGrow: 1 }}>
                                    <PreviewPartialEffect
                                        partial={isNumber(effect) ? effect : undefined}
                                        total={isNumber(effectNoScope) ? effectNoScope : null}
                                        formatCurrency={formatCurrency}
                                    />
                                </Typography>
                                <StyledFavoriteIcon value={measure.isFavorite} updateValue={toggleFavoriteMeasure} translate={translate} />
                                <UserEntryWithPopup avatarProps={{ size: 24 }} iconOnly user={assignedToUser} />
                            </Stack>
                        </Stack>
                    </Box>
                </DisguisedLink>
            </RootPaper>
        </Tooltip>
    );
}

export default React.memo(MeasureTile);
