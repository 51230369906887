import { debounce } from "lodash";
import { useMemo } from "react";
import { matomoTrackSiteSearch } from "../infrastructure/tracking";
import { DEFAULT_SEARCH_DELAY } from "./search";

export function useTrackedSearch() {
    return useMemo(
        () =>
            debounce((searchKey: string, origin: "desk" | "grid") => {
                matomoTrackSiteSearch(searchKey, origin);
            }, DEFAULT_SEARCH_DELAY),
        [],
    );
}
