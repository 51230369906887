import { EffectFilterDateField, FilteredMeasureDto, MeasureConfigDto } from "api-shared";
import { isString } from "lodash";
import moment from "moment";

export function calculateDaysUntilEffectiveness(measure: FilteredMeasureDto): number | null {
    const start = measure.calculatedFields[EffectFilterDateField.StartDate];

    if (!isString(start)) {
        return null;
    }

    return moment(start).startOf("day").diff(moment().startOf("day"), "days");
}

export function getGateConfigsInOrder(measureConfigs: MeasureConfigDto[], measureConfigId: number) {
    const config = measureConfigs.find(({ id }) => id === measureConfigId);
    const gateConfigs = config !== undefined ? config.gateTaskConfigs : [];
    return [...gateConfigs].sort((a, b) => a.order - b.order);
}
