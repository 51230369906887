import { styled, TextField, Typography } from "@mui/material";
import { zShortText } from "api-shared";
import React, { useEffect, useState } from "react";
import ActionItemDialog, { IActionItemDialogProps } from "../../components/dialogues/ActionItemDialog";
import Form from "../../components/Form";
import { SearchConfig } from "../../domain/search-config";
import { translationKeys } from "../../translations/main-translations";

type EditableSearchConfig = Pick<SearchConfig, "name"> & Partial<Pick<SearchConfig, "id">>;

const HintText = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

interface ISearchConfigDialogProps extends Pick<IActionItemDialogProps, "open" | "translate"> {
    searchConfig?: EditableSearchConfig;
    onSave: (searchConfig: EditableSearchConfig) => void;
    onClose: () => void;
}
const initialState: EditableSearchConfig = { name: "" };

const SearchConfigDialog = ({ searchConfig, onSave, onClose, translate, open }: ISearchConfigDialogProps) => {
    const [updatedSearchConfig, setUpdatedSearchConfig] = useState(searchConfig ?? initialState);
    const [isTitleTooLong, setIsTitleTooLong] = useState(false);
    const reset = () => {
        setUpdatedSearchConfig(initialState);
        setIsTitleTooLong(false);
    };

    useEffect(() => {
        // sync local state, when provided searchConfig from props changes
        setUpdatedSearchConfig(searchConfig ?? initialState);
    }, [searchConfig]);

    const isValid = updatedSearchConfig.name !== searchConfig?.name && updatedSearchConfig.name.trim().length > 0;

    const handleSubmitDialog = () => {
        if (!isValid || isTitleTooLong) {
            return;
        }
        onSave(updatedSearchConfig);
    };

    const handleSubmitForm = () => {
        if (!isValid || isTitleTooLong) {
            return;
        }
        onSave(updatedSearchConfig);
        reset();
        onClose();
    };

    const handleChangeName = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        setIsTitleTooLong(!zShortText.safeParse(target.value).success);
        setUpdatedSearchConfig((old) => ({ ...old, [target.name]: target.value }));
    };
    const isEditing = updatedSearchConfig.id !== undefined;

    const handleClose = () => {
        if (!isEditing) {
            setUpdatedSearchConfig(initialState);
        } else if (searchConfig !== undefined) {
            setUpdatedSearchConfig(searchConfig);
        }
        setIsTitleTooLong(false);
        onClose();
    };

    return (
        <ActionItemDialog
            action={isEditing ? "edit" : "create"}
            item="search-config"
            primary={isEditing ? "edit_search-config" : "create_search-config"}
            onPrimary={handleSubmitDialog}
            primaryDisabled={!isValid || isTitleTooLong}
            translate={translate}
            open={open}
            onClose={handleClose}
        >
            <Form onSubmit={handleSubmitForm}>
                {!isEditing ? <HintText color="textSecondary">{translate(translationKeys.VDLANG_SEARCH_CONFIGS_ADD_HINT)}</HintText> : null}
                <TextField
                    autoFocus
                    required
                    fullWidth
                    label={translate("Name")}
                    value={updatedSearchConfig.name}
                    name="name"
                    error={isTitleTooLong}
                    helperText={isTitleTooLong ? translate(translationKeys.VDLANG_SEARCH_CONFIGS_TITLE_TOO_LONG_HINT) : null}
                    onChange={handleChangeName}
                    placeholder={translate("Please enter a name")}
                    data-testid="submit_view"
                />
            </Form>
        </ActionItemDialog>
    );
};

export default SearchConfigDialog;
