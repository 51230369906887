import ArrowDropDownIconRounded from "@mui/icons-material/ArrowDropDownRounded";
import { Button, ButtonGroup, buttonGroupClasses, Menu, styled } from "@mui/material";
import React from "react";
import useMenu from "../hooks/useMenu";

interface ISplitButton {
    disabledButton?: boolean;
    disabledActions?: boolean;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    className?: string;
    actions: React.ReactElement[];
    label: string;
}

const GroupedButton = styled(Button)(({ theme }) => ({
    [`&.${buttonGroupClasses.grouped}`]: {
        minWidth: 0,
        "&:not(:last-of-type)": {
            borderRightColor: "white",
        },
    },
}));

const DrowDownButton = styled(GroupedButton)(({ theme }) => ({
    padding: theme.spacing(),
}));

const StyledArrowIcon = styled(ArrowDropDownIconRounded)(({ theme }) => ({
    fontSize: "1rem",
}));

const SplitButton = ({ className, actions, label, disabledButton, disabledActions, onClick }: ISplitButton) => {
    const { openMenu, makeCloseOnSelectHandler: closeMenuOnSelectHandler, menuProps } = useMenu();

    return (
        <>
            <ButtonGroup disableElevation size="small" variant="contained" className={className}>
                <GroupedButton color="primary" disabled={disabledButton} variant="contained" onClick={onClick}>
                    {label}
                </GroupedButton>
                {actions.length > 0 ? (
                    <DrowDownButton color="primary" variant="contained" size="small" onClick={openMenu} disabled={disabledActions}>
                        <StyledArrowIcon />
                    </DrowDownButton>
                ) : null}
            </ButtonGroup>
            {actions.length > 0 ? (
                // Repeat dense prop from theme, because it would be overwritten otherwise
                <Menu MenuListProps={{ disablePadding: true, dense: true }} {...menuProps}>
                    {actions.map((element) =>
                        React.cloneElement(element, {
                            // override onClick so menu can be closed when item is selected
                            onClick: closeMenuOnSelectHandler(element),
                        }),
                    )}
                </Menu>
            ) : null}
        </>
    );
};

export default SplitButton;
