import { Skeleton } from "@mui/material";
import OverlineLabel from "../OverlineLabel";

const SidebarItemSkeleton = () => (
    <>
        <OverlineLabel>
            <Skeleton width={100} />
        </OverlineLabel>
        <Skeleton variant="rounded" width="100%" height={40} />
    </>
);

export default SidebarItemSkeleton;
