import { SearchConfigDto, SearchViewType, zSearchConfigDto } from "api-shared";
import { isEmpty, isEqual, omit, pick } from "lodash";
import queryString from "query-string";
import { useCallback, useEffect, useState } from "react";
import { Location, NavigateFunction, To, useLocation, useNavigate } from "react-router-dom";
import { SearchConfig } from "../domain/search-config";
import { URL_SERIALIZATION_OPTIONS } from "../lib/navigation";
import { RouteFor } from "../lib/routes";
import useUrlSearchConfig from "./useUrlSearchConfig";

interface IUseSearchConfigProps {
    searchConfigs?: SearchConfig[];
    fallbackSearchConfigId: number;
    updateSearchConfig: (searchConfig: SearchConfig) => void;
}

// attributes that should neither be parsed from URL nor persisted in URL
const ignoredProps: (keyof SearchConfigDto)[] = ["isDefault", "name", "createdAt", "updatedAt", "userId"];

// keys for URL that may hold SearchConfig data
export const allowedProps = [
    "searchConfigId",
    ...Object.keys(zSearchConfigDto.shape).filter((k) => !ignoredProps.includes(k as keyof SearchConfigDto)),
];

export const VIEW_TYPE_ROUTES = {
    [SearchViewType.DESK]: RouteFor.measures.desk,
    [SearchViewType.GRID]: RouteFor.measures.grid,
};

/**
 * Compare the changes against the provided full searchConfig, in order to keep only the real changes. Changes, whose value equals to the
 * value in the provided SearchConfig will be dropped. This helps maintaining a minimum set of changes and keeping URL as clean as possible
 *
 * @param {SearchConfig} original
 * @param {Partial<SearchConfig>} changes
 * @returns {Partial<SearchConfig>} a cleaned changes object with the minimal set of changes wrt original
 */
function cleanupChanges(original: SearchConfig, changes: Partial<SearchConfig>): Partial<SearchConfig> {
    const changedKeys = Object.entries(changes)
        .filter(([key, value]) => !isEqual(value, original[key as keyof SearchConfig]))
        .map(([k]) => k as keyof SearchConfig)
        .filter((k) => allowedProps.includes(k));
    return pick(changes, changedKeys);
}

/**
 * Persists the diff of changes between currentConfig and changes in the URL
 *
 * @param {SearchConfig} currentConfig
 * @param {NavigateFunction} navigate
 * @param {Location} location
 * @param {Partial<SearchConfig>} [changes]
 */
function saveInUrl(currentConfig: SearchConfig, navigate: NavigateFunction, location: Location, changes?: Partial<SearchConfig>) {
    const actualChanges = changes !== undefined ? cleanupChanges(currentConfig, changes) : {};
    const { viewType, gridColumnWidths, scope, id, ...partialSearchConfig } = actualChanges;

    const sanitizedPartialSearchConfig = {
        ...partialSearchConfig,
        // nested objects are not supported for query parameters, manually stringify before, urlencoding is automatically added afterwards
        ...(gridColumnWidths && { gridColumnWidths: JSON.stringify(gridColumnWidths) }),
        ...(scope && { scope: JSON.stringify(scope) }),
        ...(!currentConfig.isDefault && { searchConfigId: id ?? currentConfig.id }), // skip persisting id of default search config
    };
    const newLocation: To = {
        search: queryString.stringify(sanitizedPartialSearchConfig, URL_SERIALIZATION_OPTIONS),
    };
    // viewType is persisted as part of the pathname, not as query parameter
    newLocation.pathname = VIEW_TYPE_ROUTES[viewType ?? currentConfig.viewType];

    const currentSearch = location.search.replace(/^\?/, ""); // remove optional leading ? from current search string
    if (newLocation.pathname !== location.pathname || newLocation.search !== currentSearch) {
        navigate(newLocation, { replace: true });
    }
}

const mergeChangesWithSearchConfig = (old: SearchConfig, changes: Partial<SearchConfig>): SearchConfig => {
    if (old === undefined) {
        // not initialized yet, so no id yet => no update possible
        // This should not happen, as there are no updates possible as long as there is no active SearchConfig
        return old;
    }

    const actualChanges = cleanupChanges(old, changes);

    if (isEmpty(actualChanges)) {
        // keep stable reference, react will not trigger a re-render then
        return old;
    }

    return { ...old, ...changes };
};

const useSearchConfig = ({ fallbackSearchConfigId, searchConfigs, updateSearchConfig: saveSearchConfig }: IUseSearchConfigProps) => {
    const location = useLocation();
    const navigate = useNavigate();

    const standardSearchConfig = searchConfigs?.find(({ isDefault }) => isDefault);

    // This is the source of truth for desk and grid, it includes a full search config object, containing also any temporary changes
    const [activeSearchConfig, setActiveSearchConfig] = useState<SearchConfig>();

    // This contains the last saved state of the current active search config
    const originalSearchConfig = searchConfigs?.find(({ id }) => id === activeSearchConfig?.id);

    const setInitialSearchConfig = useCallback(
        (urlData: Partial<SearchConfig>): void => {
            // if URL is empty (no id and no changes), use the provided fallback config id (usually lastSearchConfigId from UiState)
            // otherwise (no id, but changes in URL) fall back to default search config
            // /measures -> use fallbackSearchConfigId
            // /measures/grid and /measures/desk -> use standard search config
            // /measures/...?searchConfigId=XXX -> use XXX as search config id
            const initialActiveId = urlData.id ?? (isEmpty(urlData) ? fallbackSearchConfigId : undefined);
            const initialSearchConfig = searchConfigs?.find(({ id }) => id === initialActiveId) ?? standardSearchConfig;
            const mergedSearchConfig = initialSearchConfig !== undefined ? { ...initialSearchConfig, ...omit(urlData, "id") } : undefined;
            setActiveSearchConfig(mergedSearchConfig);

            if (urlData.viewType === undefined && mergedSearchConfig !== undefined) {
                // viewType should always be visible in URL -> make sure to set it once initially from the initial search config
                saveInUrl(mergedSearchConfig, navigate, location);
            }
        },
        [fallbackSearchConfigId, navigate, location, searchConfigs, standardSearchConfig],
    );

    // Put search config information found in URL into local state once, ignore any updated afterwards
    useUrlSearchConfig({
        onUrlDataReady: setInitialSearchConfig,
        disabled: searchConfigs === undefined, // The provided callback requires searchConfigs to be populated
    });

    const resetUnsavedChanges = useCallback(() => {
        setActiveSearchConfig(originalSearchConfig);
    }, [originalSearchConfig]);

    // It is important that the reference of the update function keeps stable, so that child components are able to do effective memoization
    const updateSearchConfig = useCallback(
        (changes: Partial<SearchConfig>) => {
            if (activeSearchConfig?.isDefault) {
                // This would be better to happen in updater function below to avoid the dependency on activeSearchConfig. Unfortunately but
                // state updaters need to be pure and can be called multiple times by react. This actually happens here and could result in
                // maximum update depth exceeded errors, as the state value is also a dependency of a useEffect below, which also gets
                // triggered more often
                const newSearchConfig = mergeChangesWithSearchConfig(activeSearchConfig, changes);
                saveSearchConfig(newSearchConfig);
            }

            setActiveSearchConfig((old) => {
                if (old === undefined) {
                    return undefined;
                }

                return mergeChangesWithSearchConfig(old, changes);
            });
        },
        [saveSearchConfig, activeSearchConfig],
    );

    // originalSearchConfig changes -> a save operation has happened -> put updated search config into local state
    useEffect(() => {
        setActiveSearchConfig((old) => {
            if (old === undefined) {
                // useUrlSearchConfig hook will take care of updating the active search config initially
                return old;
            }

            if (originalSearchConfig === undefined) {
                // No search configs yet -> no update to activeSearchConfig needed
                return old;
            }

            if (old.name !== originalSearchConfig.name) {
                // different search config selected OR search config has been renamed
                return originalSearchConfig;
            }

            // Check for local changes, that are not represented in originalSearchConfig (e.g. from initial URL data)
            const changes = cleanupChanges(originalSearchConfig, old);

            return isEmpty(changes) ? originalSearchConfig : old;
        });
    }, [originalSearchConfig]);

    const hasUnsavedChanges =
        originalSearchConfig !== undefined &&
        activeSearchConfig !== undefined &&
        !isEmpty(cleanupChanges(originalSearchConfig, activeSearchConfig));

    // Make sure to persist id of active searchConfig in URL
    useEffect(() => {
        if (activeSearchConfig === undefined || originalSearchConfig === undefined) {
            // search configs not there yet -> nothing to persist in URL
            return;
        }
        // Changes do not need to be reflected in the URL for the default search config
        // except for change from other searchConfig to standard search config
        if (activeSearchConfig.isDefault && !location.search.includes("searchConfigId")) {
            saveInUrl(activeSearchConfig, navigate, location);
        } else {
            // if there is a difference between URL and selected search config -> persist that change in URL
            saveInUrl(originalSearchConfig, navigate, location, activeSearchConfig);
        }
    }, [location, activeSearchConfig, originalSearchConfig, navigate]);

    const setActiveConfigId = useCallback(
        (newId: number) => {
            const newSearchConfig = searchConfigs?.find(({ id }) => newId === id);
            if (newSearchConfig !== undefined) {
                setActiveSearchConfig(newSearchConfig);
            }
        },
        [searchConfigs, setActiveSearchConfig],
    );

    return {
        updateSearchConfig,
        activeSearchConfig,
        standardSearchConfig,
        hasUnsavedChanges,
        resetUnsavedChanges,
        setActiveConfigId,
    };
};

export default useSearchConfig;
