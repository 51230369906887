import { Paper, styled } from "@mui/material";
import { ErrorConstantKeys, Sort } from "api-shared";
import { isEqual } from "lodash";
import { useCallback, useState } from "react";
import MeasuresTable, { IMeasuresTableProps } from "../../components/measures/MeasuresTable";
import { useFiltersQuery } from "../../domain/filters";
import { useMeasures } from "../../domain/measure/list";
import { SearchConfig } from "../../domain/search-config";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import usePrevious from "../../hooks/usePrevious";
import { resolveFilterDefinition } from "../../lib/filter-helper";
import { translationKeys } from "../../translations/main-translations";

const PaperContainer = styled(Paper)(({ theme }) => ({
    height: "100%",
}));

const Container = styled("div")(({ theme }) => ({
    height: "100%",
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
}));

interface IGridProps extends Pick<IMeasuresTableProps, "searchKey"> {
    searchConfig: SearchConfig;
    updateSearchConfig: (changes: Partial<SearchConfig>) => void;
}

const Grid = ({ searchConfig, updateSearchConfig, searchKey }: IGridProps) => {
    useDocumentTitle(translationKeys.VDLANG_SECTIONS_PROCESSES_GRID);

    const filtersQuery = useFiltersQuery();

    // page state needs to be handled here, so it can be reset whenever the query parameters change
    const [page, setPage] = useState(0);

    const updateSort = useCallback(
        (gridOrderBy: string, gridSort: Sort) =>
            updateSearchConfig({
                gridOrderBy,
                gridSort,
            }),
        [updateSearchConfig],
    );

    const updateColumnsWidths = useCallback(
        (widths: Record<string, number>) => {
            // convert to int before persisting
            const gridColumnWidths = Object.entries(widths).reduce(
                (columnWidths, [column, width]) => ({ ...columnWidths, [column]: +width.toFixed(0) }),
                {},
            );
            updateSearchConfig({ gridColumnWidths });
        },
        [updateSearchConfig],
    );

    const updatePage = useCallback(
        (pageSize: number, page: number) => {
            updateSearchConfig({ pageSize });
            setPage(page);
        },
        [updateSearchConfig],
    );

    const { gridOrderBy: orderBy, gridSort: sort, scope, pageSize, gridColumns, gridColumnWidths } = searchConfig;

    const queryWithoutPage = {
        pageSize,
        scope,
        filter: resolveFilterDefinition(filtersQuery.isSuccess ? filtersQuery.data : [], searchConfig.filterId, searchConfig.filter),
        orderBy,
        sort,
        measureConfigIds: searchConfig.measureConfigGridIds,
    };
    const previousQueryWithoutPage = usePrevious(queryWithoutPage);

    // reset page to 0 when query has changed
    // ignore page for the comparison
    const hasQueryChangesBesidesPage = !isEqual(queryWithoutPage, previousQueryWithoutPage);

    if (hasQueryChangesBesidesPage && page !== 0) {
        setPage(0);
    }

    // Avoid fetching wrong page if there are changes to query
    const measuresQuery = useMeasures("grid", {
        ...queryWithoutPage,
        page: hasQueryChangesBesidesPage ? 0 : page,
        meta: { skipReportToSentry: ErrorConstantKeys.VDERROR_INVALID_FILTER_DEFINITION },
    });

    return (
        <Container>
            <PaperContainer>
                <MeasuresTable
                    key={searchConfig.id}
                    data={measuresQuery.data}
                    fullHeight
                    orderBy={orderBy}
                    sort={sort}
                    pageIndex={page}
                    pageSize={pageSize}
                    columns={gridColumns}
                    onSortChanged={updateSort}
                    onPageChanged={updatePage}
                    // half controlled mode, so provide only inital value and subscribe to updates
                    defaultColumnWidths={gridColumnWidths}
                    onColumnsResized={updateColumnsWidths}
                    searchKey={searchKey}
                    isFetching={!measuresQuery.isSuccess}
                />
            </PaperContainer>
        </Container>
    );
};

export default Grid;
