import { UniqueIdentifier } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import SearchConfigItem, { ISearchConfigItemProps } from "./SearchConfigItem";
interface IDraggableSearchConfigItemProps extends ISearchConfigItemProps {
    id: UniqueIdentifier;
    name: string;
    value: string;
}

const DraggableSearchConfigItem = ({ id, name, ...otherProps }: IDraggableSearchConfigItemProps) => {
    const { active, attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <SearchConfigItem
            ref={setNodeRef}
            style={style}
            id={id}
            name={name}
            active={active?.id === id}
            {...attributes}
            {...listeners}
            {...otherProps}
        />
    );
};

export default DraggableSearchConfigItem;
