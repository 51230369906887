import { Stack, Typography, styled } from "@mui/material";
import { InfiniteData, useIsFetching, useQueryClient } from "@tanstack/react-query";
import { CurrentGateType, EffectFilterCurrencyField, FilteredMeasuresDto, nonNullable } from "api-shared";
import { TFunction } from "i18next";
import React from "react";
import { useFiltersQuery } from "../../domain/filters";
import { useMeasureConfigs } from "../../domain/measure-config";
import { useMeasureConversionRates } from "../../domain/measure/conversion-rates";
import { MeasureListKeys } from "../../domain/measure/list";
import { SearchConfig } from "../../domain/search-config";
import { resolveFilterDefinition } from "../../lib/filter-helper";
import { getGateConfigsInOrder } from "../../lib/measure";
import { translationKeys } from "../../translations/main-translations";
import MoneyChip from "../MoneyChip";
import Tooltip from "../Tooltip";

const Container = styled(Stack)(({ theme }) => ({
    padding: theme.spacing(1.5, 2),
    borderTop: `1px solid ${theme.palette.divider}`,
}));

const NumberTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
}));

interface IMeasuresTotalSummaryProps {
    searchConfig: SearchConfig;
    translate: TFunction;
}

// TODO: TS move to domain/measure
export interface MeasuresByCategory {
    [x: number]: FilteredMeasuresDto & { isFetching: boolean };
}

const MeasuresTotalSummary = ({ searchConfig, translate }: IMeasuresTotalSummaryProps) => {
    const filters = useFiltersQuery();
    const conversionRateQuery = useMeasureConversionRates({
        measureConfigId: searchConfig.measureConfigDeskId,
        filter: resolveFilterDefinition(filters.isSuccess ? filters.data : [], searchConfig.filterId, searchConfig.filter),
        scope: searchConfig.scope,
    });

    const measureConfigs = useMeasureConfigs();
    // subscribe to all active (=needed by rendered components) desk queries
    // will trigger a re-render everytime the number of active queries changes
    const noOfFetchingQueries = useIsFetching({ queryKey: MeasureListKeys.deskAll(), stale: false, type: "active" });

    // useFetching needs to be implemented twice, so that total sum is updated even if queries are stale
    useIsFetching({ queryKey: MeasureListKeys.deskAll(), type: "active" });

    // get query data, might be incomplete until all queries are finished
    const queryClient = useQueryClient();
    const deskQueryData = queryClient.getQueriesData<InfiniteData<FilteredMeasuresDto> | undefined>({
        queryKey: MeasureListKeys.deskAll(),
        type: "active",
    });

    const columns =
        searchConfig.measureConfigDeskId !== undefined
            ? getGateConfigsInOrder(measureConfigs, searchConfig.measureConfigDeskId).map(({ id }) => id)
            : [];
    columns.push(CurrentGateType.GATE_CLOSED);
    const allColumnsAvailable = noOfFetchingQueries === 0;

    // compute total effect & count across all desk columns
    const total = deskQueryData
        .map(([queryKey, queryData]) => queryData?.pages)
        .filter(nonNullable)
        .map((pages) => (pages.length > 0 ? pages[pages.length - 1] : null))
        .reduce(
            (totals, page) => {
                totals.count += page?.matchingItems ?? 0;
                totals.effect += page?.sums[EffectFilterCurrencyField.Effect] ?? 0;
                return totals;
            },
            { count: 0, effect: 0 },
        );

    // Calculate TotalConversionRate
    let totalConversionRate: number | null = null;
    if (conversionRateQuery.isSuccess && conversionRateQuery.fetchStatus !== "idle") {
        totalConversionRate = Object.values(conversionRateQuery.data.gateConversions).reduce((acc, rate) => acc * rate, 1);
    }

    return (
        <Container direction="row" justifyContent="space-between" alignItems="center">
            {allColumnsAvailable ? (
                <Tooltip title={`${total.count} ${translate("processes")}`}>
                    <NumberTypography>{translate(translationKeys.VDLANG_PROCESS_ABBREVIATION, { count: total.count })}</NumberTypography>
                </Tooltip>
            ) : (
                <div />
            )}
            {allColumnsAvailable ? <MoneyChip short value={total.effect} /> : <div />}
            {totalConversionRate !== null ? (
                <Tooltip title={translate(translationKeys.VDLANG_GRID_TOTAL_CONVERSION_RATE_TOOLTIP)}>
                    <NumberTypography>{Math.round(totalConversionRate * 100)}%</NumberTypography>
                </Tooltip>
            ) : (
                <div />
            )}
        </Container>
    );
};
export default React.memo(MeasuresTotalSummary);
