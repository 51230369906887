import { UniqueIdentifier } from "@dnd-kit/core";
import { Button, ButtonProps, styled, TabProps } from "@mui/material";
import React from "react";

const RootButton = styled(Button, { shouldForwardProp: (name) => name !== "active" && name !== "isDragOverlay" && name !== "selected" })<{
    active?: boolean;
    isDragOverlay?: boolean;
    selected?: boolean;
}>(({ theme, active, isDragOverlay, selected }) => ({
    padding: theme.spacing(1, 1.5),
    minHeight: 0,
    minWidth: 0,
    maxWidth: "unset",
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
    flexShrink: 0,
    "&:hover": {
        backgroundColor: theme.palette.action.hover,
    },
    // disable whitespace wrapping, because DragOverlay is rendered in a different context and could wrap
    whiteSpace: "nowrap",
    lineHeight: "1rem",
    ...(active && {
        // there will be a dedicated DragOverlay element rendered for the element under the pointer, so hide this one (but still occupy
        // space)
        visibility: "hidden",
    }),
    ...(isDragOverlay && {
        cursor: "grabbing",
        boxShadow: theme.shadows[3],
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.secondary,
        "&:hover": {
            backgroundColor: theme.palette.background.paper,
        },
    }),
    ...(selected && {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.action.selected,
    }),
}));

export interface ISearchConfigItemProps extends Omit<ButtonProps, "id">, Omit<TabProps, keyof ButtonProps> {
    id: UniqueIdentifier;
    name: string;
    active?: boolean;
    isDragOverlay?: boolean;
    selected?: boolean;
    className?: string;
    // props that could possibly injected by parent Tabs component but are missing in TabProps typings
    indicator?: unknown;
    selectionFollowsFocus?: unknown;
}

const SearchConfigItem = React.forwardRef<HTMLButtonElement, ISearchConfigItemProps>(
    (
        {
            id,
            name,
            // Depending on context, surrounding Tabs component may inject some properties intended for a children Tab component
            // Some of the are not supported by Button, so remove those
            fullWidth,
            indicator,
            selectionFollowsFocus,
            ...buttonProps
        },
        ref,
    ) => {
        return (
            <RootButton size="small" variant="text" color="inherit" ref={ref} {...buttonProps}>
                {name}
            </RootButton>
        );
    },
);

export default React.memo(SearchConfigItem);
