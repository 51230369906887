import { Divider, Grid, styled } from "@mui/material";
import { CurrentGateType } from "api-shared";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import { useFiltersQuery } from "../../domain/filters";
import { useMeasureConfigs } from "../../domain/measure-config";
import { useMeasureConversionRates } from "../../domain/measure/conversion-rates";
import { SearchConfig } from "../../domain/search-config";
import { useAllUsers } from "../../domain/users";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { resolveFilterDefinition } from "../../lib/filter-helper";
import { getGateConfigsInOrder } from "../../lib/measure";
import { translationKeys } from "../../translations/main-translations";
import DeskColumn from "./desk/DeskColumn";

const RootComponent = styled(Grid)(({ theme }) => ({
    height: "100%",
    padding: theme.spacing(0, 3),
    backgroundColor: theme.palette.grey[100],
    overflow: "auto",
}));

interface IDeskProps {
    searchConfig: SearchConfig;
    searchKey: string;
}

const Desk = ({ searchConfig, searchKey }: IDeskProps) => {
    useDocumentTitle(translationKeys.VDLANG_SECTIONS_PROCESSES_DESK, true);

    const { t: translate } = useTranslation();
    const measureConfigs = useMeasureConfigs();
    const filtersQuery = useFiltersQuery();
    const users = useAllUsers();

    const conversationRateQuery = useMeasureConversionRates({
        measureConfigId: searchConfig.measureConfigDeskId,
        filter: resolveFilterDefinition(filtersQuery.isSuccess ? filtersQuery.data : [], searchConfig.filterId, searchConfig.filter),
        scope: searchConfig.scope,
    });

    if (!filtersQuery.isSuccess) {
        return <LoadingAnimation />;
    }

    const getColumnTitle = (column: number) => {
        const measureConfig = measureConfigs.find((mc) => mc.id === searchConfig.measureConfigDeskId);
        const gateTaskConfig = measureConfig?.gateTaskConfigs.find((gtc) => gtc.id === column);
        return gateTaskConfig !== undefined ? `desk_${gateTaskConfig.name}` : `desk_${column}`;
    };

    const columns = getGateConfigsInOrder(measureConfigs, searchConfig.measureConfigDeskId).map(({ id }) => ({
        id,
        title: getColumnTitle(id),
    }));
    columns.push({
        id: CurrentGateType.GATE_CLOSED,
        title: getColumnTitle(CurrentGateType.GATE_CLOSED),
    });

    return (
        <RootComponent container wrap="nowrap">
            {columns.map(({ id, title }) => (
                <Fragment key={id}>
                    <Grid item>
                        <DeskColumn
                            title={title}
                            filters={filtersQuery.data}
                            searchConfig={searchConfig}
                            name={id}
                            users={users}
                            additionalInformation={searchConfig.additionalInformation}
                            translate={translate}
                            searchKey={searchKey}
                            conversionRate={conversationRateQuery.data?.gateConversions[id]}
                        />
                    </Grid>
                    <Grid item>
                        <Divider orientation="vertical" />
                    </Grid>
                </Fragment>
            ))}
        </RootComponent>
    );
};

export default React.memo(Desk);
