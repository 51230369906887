import { Paper, Skeleton, Stack, styled, Typography, useTheme } from "@mui/material";
import {
    AdditionalInformation,
    CurrentGateType,
    EffectField,
    FilterDto,
    FilteredMeasureDto,
    FilteredMeasuresDto,
    UserDto,
} from "api-shared";
import { TFunction } from "i18next";
import { times } from "lodash";
import React, { useEffect, useMemo, useRef } from "react";
import MoneyChip from "../../../components/MoneyChip";
import Tooltip from "../../../components/Tooltip";
import { useDeskMeasures } from "../../../domain/measure/list";
import { SearchConfig } from "../../../domain/search-config";
import useInfiniteScrollingState from "../../../hooks/useInfiniteScrollingState";
import { useLanguage } from "../../../hooks/useLanguage";
import useOverlapState from "../../../hooks/useOverlapState";
import { generateColumnQuery, resolveFilterDefinition } from "../../../lib/filter-helper";
import { quickSearch } from "../../../lib/quick-search";
import { stripPxSuffix } from "../../../styles/utils";
import { translationKeys } from "../../../translations/main-translations";
import DeskTile from "./DeskTile";

// fixed reference to an empty dataset
const EMPTY_DATA: FilteredMeasuresDto = {
    measures: [],
    matchingItems: 0,
    sums: {},
};

const Root = styled(Stack)(({ theme }) => ({
    // make sure empty lanes have equal width as filled ones
    // allow column to get wider, which is needed in case that scrollbar takes up additional space but tiles should not shrink
    minWidth: 316,
    height: "100%",
    overflow: "hidden", // hide horizontally leaking shadows of elevated header/footer
}));

const ScrollContainer = styled(Stack)(({ theme }) => ({
    height: "100%",
    overflowY: "auto",
    // prevent edge including scrollbar size into calculations, resulting in an additional horizontal scrollbar
    msOverflowStyle: "-ms-autohiding-scrollbar",
    "& > *": {
        flexShrink: 0, // Avoid children to shrink, so that scroll bar can appear
        // override margin:0 set by Stack with a very specific selector :not(style) + :not(style)
        marginLeft: "auto !important",
        marginRight: "auto !important",
    },
}));

const Elevatable = styled("div", { shouldForwardProp: (name) => name !== "isElevated" })<{ isElevated: boolean }>(
    ({ theme, isElevated }) => ({
        ...(isElevated && {
            boxShadow: theme.shadows[3],
            zIndex: theme.zIndex.appBar,
        }),
    }),
);

const StyledPaper = styled(Paper)({
    width: 288,
    height: 110,
    overflow: "hidden",
});

const DeskTileSkeleton = React.forwardRef<HTMLDivElement, unknown>((_, ref) => (
    <StyledPaper ref={ref} elevation={0} variant="elevation">
        <Skeleton variant="rectangular" width="100%" height="100%" />
    </StyledPaper>
));

interface DeskColumnProps {
    name: number;
    title: string;
    users: UserDto[];
    searchKey: string;
    conversionRate?: number;
    additionalInformation: AdditionalInformation;
    translate: TFunction;
    filters: FilterDto[];
    searchConfig: SearchConfig;
}

function DeskColumn({
    name,
    title,
    users,
    searchKey,
    conversionRate,
    additionalInformation,
    translate,
    filters,
    searchConfig,
}: Readonly<DeskColumnProps>) {
    const theme = useTheme();

    const language = useLanguage();

    const listRef = useRef<HTMLDivElement>(null);

    // padding of list container is also needed for computation of elevation. To keep elevation computation consistent with actual padding,
    // attach the padding with styles prop
    const listPadding = stripPxSuffix(theme.spacing(1));

    const resolvedFilter = resolveFilterDefinition(filters, searchConfig.filterId, searchConfig.filter);
    const queryOptions = generateColumnQuery(name, searchConfig, resolvedFilter);
    const measuresQuery = useDeskMeasures(name, queryOptions);

    const searchResults = useMemo(
        () => quickSearch(searchKey, measuresQuery.data?.pages.flatMap((page) => page.measures) ?? []),
        [searchKey, measuresQuery.data?.pages],
    );

    const { hasBottomOverlap, hasTopOverlap, updateOverlap } = useOverlapState(listRef, listPadding);

    // recompute elevation when number of tiles changed due to quick search
    useEffect(() => updateOverlap(), [searchResults.length, updateOverlap]);

    const pages = measuresQuery.isSuccess ? measuresQuery.data.pages : [];
    const latestPage = pages[pages.length - 1] ?? EMPTY_DATA;
    const hasNextPage = Boolean(measuresQuery.hasNextPage);

    const { guardRef, rootRef } = useInfiniteScrollingState({
        isFetching: measuresQuery.isFetching,
        hasNextPage,
        // ignore provided page index, page handling is done by react-query
        loadPage: () => {
            measuresQuery.fetchNextPage();
        },
        parentRef: listRef,
    });

    const effectSum = latestPage.sums[EffectField.Effect];

    return (
        <Root>
            <Elevatable sx={{ p: 3, pb: 1.5 }} isElevated={hasTopOverlap}>
                <Typography color="textSecondary" component="div">
                    <Stack direction="row" spacing={1}>
                        <Typography color="textSecondary" fontWeight="medium">
                            {translate(title)}
                        </Typography>
                        <Typography color="textSecondary">{measuresQuery.isSuccess ? latestPage?.matchingItems : null}</Typography>
                    </Stack>
                </Typography>
            </Elevatable>
            <ScrollContainer spacing={1} style={{ padding: listPadding }} onScroll={updateOverlap} ref={rootRef}>
                {measuresQuery.isSuccess
                    ? searchResults.map((measure: FilteredMeasureDto) => (
                          <DeskTile
                              key={measure.id}
                              measure={measure}
                              additionalInformation={additionalInformation}
                              users={users}
                              translate={translate}
                          />
                      ))
                    : null}
                {!measuresQuery.isSuccess || hasNextPage ? <DeskTileSkeleton ref={guardRef} key="loader" /> : null}
                {!measuresQuery.isSuccess ? times(2, (index) => <DeskTileSkeleton key={`skeleton_${index}`} />) : null}
            </ScrollContainer>
            <Elevatable sx={{ p: 3, pt: 1.5 }} isElevated={hasBottomOverlap}>
                <Stack width="100%" direction="row" justifyContent="space-between">
                    {measuresQuery.isSuccess && effectSum != null ? <MoneyChip short value={effectSum} /> : <Skeleton width={50} />}
                    {name !== CurrentGateType.GATE_CLOSED ? ( // Hide conversion rate in last column
                        conversionRate != null ? (
                            <Tooltip title={translate(translationKeys.VDLANG_GRID_CONVERSION_RATE_TOOLTIP)}>
                                <Typography color="primary" fontWeight="medium">
                                    {conversionRate?.toLocaleString(language, { style: "percent", maximumFractionDigits: 0 })}
                                </Typography>
                            </Tooltip>
                        ) : (
                            <Skeleton width={50} />
                        )
                    ) : null}
                </Stack>
            </Elevatable>
        </Root>
    );
}

export default React.memo(DeskColumn);
