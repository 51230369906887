import { Box, Divider, Grid, Skeleton, styled, TabsActions } from "@mui/material";
import { AclNamespaces, AclPermissions, ScopeDto, SearchViewType } from "api-shared";
import { pick, times, uniq } from "lodash";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Authorization from "../../../components/Authorization";
import SearchInput from "../../../components/input/SearchInput";
import MeasuresTotalSummary from "../../../components/measures/MeasuresTotalSummary";
import SidebarItemSkeleton from "../../../components/sidebar/SidebarItemSkeleton";
import SidebarPreferences from "../../../components/sidebar/SidebarPreferences";
import { useClientFiscalYear, useCurrentClient } from "../../../domain/client";
import { useMeasureAttributes } from "../../../domain/endpoint";
import { useFiltersQuery, useMeasureFieldDefinitionsQuery } from "../../../domain/filters";
import { useMeasureConfigs } from "../../../domain/measure-config";
import { SearchConfig } from "../../../domain/search-config";
import { RouteFor } from "../../../lib/routes";
import { translationKeys } from "../../../translations/main-translations";
import AdditionalInformationSelect from "./AdditionalInformationSelect";
import ColumnConfiguration from "./ColumnConfiguration";
import DeskOrderSelect from "./DeskOrderSelect";
import FilterSelect from "./filter-configuration/FilterSelect";
import MeasureConfigSelect from "./MeasureConfigSelect";
import { MeasuresCSVExportButton } from "./MeasuresCSVExportButton";
import ScopeSelect from "./ScopeSelect";
import ViewSelect from "./ViewSelect";

const ExportButton = styled(MeasuresCSVExportButton)(({ theme }) => ({
    margin: theme.spacing(2),
}));

const OutsetDivider = styled(Divider)(({ theme }) => ({
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
}));

const StyledColumnConfiguration = styled(ColumnConfiguration)(({ theme }) => ({
    marginBottom: theme.spacing(),
}));

const ListContainer = styled(Grid)(({ theme }) => ({
    height: "100%",
    paddingTop: theme.spacing(3),
}));

function MeasurePreferencesSkeleton() {
    return (
        <SidebarPreferences header={<ViewSelect disabled />}>
            <ListContainer container alignContent="flex-start">
                {/* Skeleton for search input */}
                <Grid item xs={12}>
                    <Skeleton variant="rounded" height={40} width="100%" />
                </Grid>
                {times(3, (i) => (
                    <Grid key={i} item xs={12}>
                        <SidebarItemSkeleton />
                    </Grid>
                ))}
            </ListContainer>
        </SidebarPreferences>
    );
}

interface MeasuresPreferencesProps {
    searchConfig?: SearchConfig;
    updateSearchConfig: (searchConfig: Partial<SearchConfig>) => void;
    updateSearchKey: (searchKey: string) => void;
    searchKey: string;
    action: (actions: TabsActions) => void;
}

const MeasuresPreferences = ({ searchConfig, updateSearchConfig, updateSearchKey, searchKey, action }: MeasuresPreferencesProps) => {
    const client = useCurrentClient();
    const measureConfigs = useMeasureConfigs();
    const fiscalYear = useClientFiscalYear();
    const measureAttributes = useMeasureAttributes();

    const fieldDefinitions = useMeasureFieldDefinitionsQuery();
    const filters = useFiltersQuery();
    const navigate = useNavigate();
    const { t: translate } = useTranslation();

    const updateView = useCallback(
        (newView: SearchViewType) => {
            navigate({
                // keep current search parameters
                pathname: newView === SearchViewType.GRID ? RouteFor.measures.grid : RouteFor.measures.desk,
            });
            updateSearchConfig({ viewType: newView });
        },
        [navigate, updateSearchConfig],
    );

    const selectFilter = useCallback(
        (filterId: number | null) => {
            if (filterId != null) {
                updateSearchConfig({ filterId });
            }
        },
        [updateSearchConfig],
    );

    const updateMeasureConfigs = useCallback(
        (selectedMeasureConfigs: number | number[]) => {
            // update makes no sense if the search config has not been loaded
            if (searchConfig == undefined) {
                return;
            }

            //  we need to handle invalid attributes based on the new measureConfig selection and configured attributes
            const newMeasureConfigIds = Array.isArray(selectedMeasureConfigs) ? selectedMeasureConfigs : [selectedMeasureConfigs];
            const newMeasureConfigs = measureConfigs.filter((mc) => newMeasureConfigIds.includes(mc.id));
            const currentAttributes = searchConfig.scope.attributes;

            // all attributes from selected measureconfigs
            const validAttributes = uniq(newMeasureConfigs.flatMap((mc) => mc.effectCategoryAttributes.map((ecattr) => ecattr.title)));

            // pick only valid attributes
            const attributes = pick(currentAttributes, validAttributes);

            if (Array.isArray(selectedMeasureConfigs)) {
                // Grid
                updateSearchConfig({ measureConfigGridIds: selectedMeasureConfigs, scope: { ...searchConfig.scope, attributes } });
            } else {
                // Desk
                updateSearchConfig({ measureConfigDeskId: selectedMeasureConfigs, scope: { ...searchConfig.scope, attributes } });
            }
        },
        [updateSearchConfig, measureConfigs, searchConfig],
    );
    const updateScope = useCallback((newScope: ScopeDto) => updateSearchConfig({ scope: newScope }), [updateSearchConfig]);

    if (searchConfig === undefined || measureConfigs === undefined || !fieldDefinitions.isSuccess || !filters.isSuccess) {
        return <MeasurePreferencesSkeleton />;
    }

    const { viewType, measureConfigDeskId, measureConfigGridIds } = searchConfig;

    const measureConfigIds = viewType === SearchViewType.DESK ? measureConfigDeskId : measureConfigGridIds;

    return (
        <SidebarPreferences
            header={<ViewSelect viewType={viewType} updateView={updateView} action={action} />}
            footer={
                viewType === SearchViewType.DESK ? (
                    <MeasuresTotalSummary searchConfig={searchConfig} translate={translate} />
                ) : (
                    <Authorization namespace={AclNamespaces.Api} permission={AclPermissions.Export}>
                        <ExportButton searchConfig={searchConfig} />
                    </Authorization>
                )
            }
        >
            <ListContainer container alignContent="flex-start">
                <Grid item xs={12}>
                    <SearchInput
                        placeholder={translate(translationKeys.VDLANG_DESK_GRID_SEARCH)}
                        searchKey={searchKey}
                        translate={translate}
                        onChange={updateSearchKey}
                        sx={{ mb: 1 }}
                    />
                </Grid>
                {viewType === SearchViewType.GRID ? (
                    <Grid item xs={12}>
                        <StyledColumnConfiguration
                            fieldDefinitions={fieldDefinitions.data}
                            columns={searchConfig.gridColumns}
                            updateColumns={(c) => updateSearchConfig({ gridColumns: c })}
                        />
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <MeasureConfigSelect
                        isMulti={viewType === SearchViewType.GRID}
                        values={viewType === SearchViewType.GRID ? searchConfig.measureConfigGridIds : searchConfig.measureConfigDeskId}
                        updateValues={updateMeasureConfigs}
                        translate={translate}
                        measureConfigs={measureConfigs}
                    />
                </Grid>
                <Grid item xs={12}>
                    <OutsetDivider />
                    <Box py={1.5}>
                        <ScopeSelect
                            // re-init component on measure config change. Needed because useFieldData cannot handle changing field lists
                            key={searchConfig.measureConfigGridIds.join()}
                            financialMonth={fiscalYear}
                            translate={translate}
                            yearsBefore={client.fiscalYearRangePast}
                            yearsAfter={client.fiscalYearRangeFuture}
                            measureConfigIds={measureConfigIds}
                            measureConfigs={measureConfigs}
                            value={searchConfig.scope}
                            onChange={updateScope}
                            label={translate(translationKeys.VDLANG_MEASURES_SIDEBAR_SCOPE)}
                        />
                    </Box>
                    <OutsetDivider />
                </Grid>
                <Grid item xs={12}>
                    <FilterSelect
                        value={searchConfig.filterId}
                        filters={filters.data}
                        updateSelectedFilter={selectFilter}
                        translate={translate}
                        searchConfig={searchConfig}
                        measureAttributes={measureAttributes}
                        label={translate(translationKeys.VDLANG_FILTER_FILTERS)}
                    />
                </Grid>
                {viewType === SearchViewType.DESK ? (
                    <Grid item xs={12}>
                        <DeskOrderSelect
                            value={searchConfig.deskOrderBy}
                            order={searchConfig.deskSort}
                            translate={translate}
                            onChange={(deskOrderBy, deskSort) =>
                                deskOrderBy != null && deskSort != null && updateSearchConfig({ deskOrderBy, deskSort })
                            }
                        />
                    </Grid>
                ) : null}
                {viewType === SearchViewType.DESK && (
                    <Grid item xs={12}>
                        <AdditionalInformationSelect
                            value={searchConfig.additionalInformation}
                            onChange={(additionalInformation) =>
                                additionalInformation != null && updateSearchConfig({ additionalInformation })
                            }
                            translate={translate}
                        />
                    </Grid>
                )}
            </ListContainer>
        </SidebarPreferences>
    );
};

export default MeasuresPreferences;
