import { CurrentGateType, DEFAULT_FILTER_ID, FilterDefinition, FilterDto, MeasureFieldNames, Operators } from "api-shared";
import { IMeasuresQuery } from "../domain/measure/list";
import { SearchConfig } from "../domain/search-config";
import { DEFAULT_FILTER_PAGE_SIZE } from "./defaults";

/**
 * Extend the given filter to account for the given gate and merge the filter with the SearchConfig. Resets the page to the first page.
 * The result can be used for getting measures.
 * @param {CurrentGateType} column
 * @param {number} page
 * @param {SearchConfig} searchConfig
 * @param {FilterDefinition} selectedFilterDefinition
 * @returns {IMeasuresQuery}
 */
export const generateColumnQuery = (
    column: CurrentGateType,
    searchConfig: SearchConfig,
    selectedFilterDefinition: FilterDefinition = [],
): IMeasuresQuery => {
    const filter: FilterDefinition = [
        ...selectedFilterDefinition,
        {
            operator: Operators.In,
            field: MeasureFieldNames.CurrentGateTaskConfigId,
            values: [column.toString()],
        },
    ];

    // remove filterId, so that the filter definition will be used
    const { filterId, deskOrderBy, deskSort, measureConfigDeskId, measureConfigGridIds, ...cleanSearchConfig } = searchConfig;
    return {
        ...cleanSearchConfig,
        measureConfigIds: [searchConfig.measureConfigDeskId],
        orderBy: deskOrderBy,
        sort: deskSort,
        filter,
        pageSize: DEFAULT_FILTER_PAGE_SIZE,
        byCategory: column,
    };
};

/**
 * Resolve a filter id among the given set of filters
 *
 * @export
 * @param {FilterDto[]} filters
 * @param {number} [filterId]
 * @param {(FilterDefinition | string)} [override]
 * @returns {(FilterDefinition | undefined)}
 */
export function resolveFilterDefinition(
    filters: FilterDto[],
    filterId?: number | null,
    override?: FilterDefinition | string,
): FilterDefinition | undefined {
    if (typeof override === "string") {
        return JSON.parse(override) as FilterDefinition;
    }
    return (
        override ?? filters.find(({ id }) => id === filterId)?.definition ?? filters.find(({ id }) => id === DEFAULT_FILTER_ID)?.definition
    );
}
