import { TabsActions } from "@mui/material";
import { useCallback, useRef } from "react";

interface IUseTabActionsReturnValue {
    setActions: (actions: TabsActions) => void;
    // Re-expose TabActions functions as unbound functions
    updateIndicator: () => void;
    updateScrollButtons: () => void;
}

/**
 * Reference handling of TabActions and safety layer for calling the TabActions' functions
 *
 * @returns {IUseTabActionsReturnValue}
 */
const useTabActions = (): IUseTabActionsReturnValue => {
    const action = useRef<TabsActions | null>(null);

    const setActions = (actions: TabsActions) => (action.current = actions);

    const updateIndicator = useCallback(() => {
        if (action?.current == null) {
            return;
        }
        typeof action.current.updateIndicator === "function" && action.current.updateIndicator();
    }, []);

    const updateScrollButtons = useCallback(() => {
        if (action?.current == null) {
            return;
        }
        typeof action.current.updateScrollButtons === "function" && action.current.updateScrollButtons();
    }, []);

    return { setActions, updateIndicator, updateScrollButtons };
};

export default useTabActions;
