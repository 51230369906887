import { closestCenter, DndContext, DragOverlay } from "@dnd-kit/core";
import { restrictToFirstScrollableAncestor, restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import { horizontalListSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import { styled, Tabs, tabsClasses } from "@mui/material";
import { sortBy } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { SearchConfig } from "../../domain/search-config";
import useSortableList from "../../hooks/useSortableList";
import DraggableSearchConfigItem from "./DraggableSearchConfigItem";
import SearchConfigItem from "./SearchConfigItem";

const SearchConfigTabs = styled(Tabs)(({ theme }) => ({
    minHeight: 0, // allow being only as high as content
    [`& .${tabsClasses.indicator}`]: {
        display: "none",
    },
}));

interface IDnDSearchConfigListProps {
    selectedId: number | undefined;
    searchConfigs: SearchConfig[];
    order: number[];
    onSearchConfigSelect: (id: number) => void;
    onOrderUpdate: (newOrder: number[]) => void;
}

function resolveSearchConfigOrder(searchConfigs: SearchConfig[], order: number[]) {
    // default search config is shown seperately
    const nonStandardSearchConfigs = searchConfigs.filter(({ isDefault }) => !isDefault);

    // use given order first, ignore ids whose search configs do not exist anymore
    const orderedConfigs = order
        .map((id) => nonStandardSearchConfigs.find((searchConfig) => searchConfig.id === id))
        .filter((x): x is SearchConfig => x !== undefined);

    // append all other search configs in 'createdAt' ASC order
    const remainder = nonStandardSearchConfigs.filter((sc) => !orderedConfigs.includes(sc));
    const orderedRemainder = sortBy(remainder, "createdAt");

    return [...orderedConfigs, ...orderedRemainder].map(({ id, name }) => ({ id: String(id), name }));
}

const DragAndDropModifiers = [restrictToHorizontalAxis, restrictToFirstScrollableAncestor];

const DnDSearchConfigList = ({ selectedId, searchConfigs, order, onSearchConfigSelect, onOrderUpdate }: IDnDSearchConfigListProps) => {
    const [orderedConfigs, setOrderedConfigs] = useState(() => resolveSearchConfigOrder(searchConfigs, order));

    // keep local state in sync with upstream changes (e.g. deletion) of searchConfigs or order
    useEffect(() => {
        const updatedOrder = resolveSearchConfigOrder(searchConfigs, order);
        setOrderedConfigs(updatedOrder);
    }, [searchConfigs, order]);

    const updateSearchConfigOrder = useCallback(
        (newOrder: { id: string; name: string }[]) => {
            // save local state first, for glitchless updates of the drag and drop components
            setOrderedConfigs(newOrder);
            // also persist new order in UiState
            onOrderUpdate(newOrder.map(({ id }) => +id));
        },
        [onOrderUpdate],
    );

    const { activeId, sensors, onDragEnd, onDragStart } = useSortableList({
        items: orderedConfigs,
        updateItems: updateSearchConfigOrder,
    });

    const handleSelect = (e: React.MouseEvent<HTMLButtonElement>) => {
        const { value } = e.currentTarget;
        onSearchConfigSelect(+value);
    };

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            modifiers={DragAndDropModifiers}
        >
            <SortableContext items={orderedConfigs} strategy={horizontalListSortingStrategy}>
                <SearchConfigTabs value={selectedId !== undefined ? String(selectedId) : false} variant="scrollable" scrollButtons="auto">
                    {orderedConfigs.map(({ id, name }) => (
                        // Tabs component is looking for "value" prop on its direct children, not on any Tab component below
                        // so it's needed to have the value prop here
                        <DraggableSearchConfigItem key={id} value={id} id={id} name={name} onClick={handleSelect} />
                    ))}
                </SearchConfigTabs>
                <DragOverlay>
                    {activeId != null ? (
                        <SearchConfigItem isDragOverlay id={activeId} name={orderedConfigs.find(({ id }) => id === activeId)?.name ?? ""} />
                    ) : null}
                </DragOverlay>
            </SortableContext>
        </DndContext>
    );
};

export default DnDSearchConfigList;
