import { DEFAULT_FILTER_ID, FieldDefinitionsDto, FilterDto, MeasureConfigDto } from "api-shared";
import { findKey, omit, pick } from "lodash";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useFiltersQuery, useMeasureFieldDefinitionsQuery } from "../domain/filters";
import { useMeasureConfigsQuery } from "../domain/measure-config";
import { SearchConfig } from "../domain/search-config";
import { URL_SERIALIZATION_OPTIONS } from "../lib/navigation";
import { VIEW_TYPE_ROUTES, allowedProps } from "./useSearchConfig";

type UseUrlSearchConfigOptions = {
    onUrlDataReady: (data: Partial<SearchConfig>) => void;
    disabled: boolean;
};

function parseUrl(
    search: string,
    pathname: string,
    fieldDefinitions: FieldDefinitionsDto,
    measureConfigs: MeasureConfigDto[],
    filters: FilterDto[],
): Partial<SearchConfig> {
    // some entries in search config contain objects, that won't properly be (de)serialized, those need to be wrapped in
    // JSON.stringify/JSON.parse
    const sensitiveKeys = ["gridColumnWidths", "scope"] as const;
    const parseResult = queryString.parse(search, { parseNumbers: true, parseBooleans: true, ...URL_SERIALIZATION_OPTIONS });
    const { searchConfigId, ...parsedSearch } = omit(pick(parseResult, allowedProps), sensitiveKeys);

    sensitiveKeys.forEach((key) => {
        const value = parseResult[key];
        if (typeof value === "string" && value !== "[object Object]" && value !== '"[object Object]"') {
            // add it back only if it's valid
            try {
                parsedSearch[key] = JSON.parse(value);
            } catch (e) {
                // Ignore any errors, which may be caused by arbitrary user input
            }
        }
    });

    // Support for legacy URLs
    if (
        typeof parsedSearch.timerangeStart === "string" &&
        typeof parsedSearch.timerangeEnd === "string" &&
        parsedSearch.scope === undefined
    ) {
        parsedSearch.scope = { startDate: parsedSearch.timerangeStart, endDate: parsedSearch.timerangeEnd, attributes: {} } as any;
    }

    // viewType is persisted as part of the pathname, not as query parameter
    const viewType = findKey(VIEW_TYPE_ROUTES, (v) => v === pathname);

    const urlData = {
        ...parsedSearch,
        ...(viewType !== undefined && { viewType: +viewType }),
        ...(typeof searchConfigId === "number" && { id: searchConfigId }),
    };

    const cleanData = sanitizeUrlData(urlData, fieldDefinitions, measureConfigs, filters);

    // only allow whitelisted props to pass here
    return pick(cleanData, allowedProps);
}

/**
 * Do validations according to backend validation
 * 1. Check for allowed fields
 * 2a. Validate measure config ids
 * 2b. Validate scope attributes based on measure config
 * 3. Validate filter
 *
 * @param {Partial<SearchConfig>} urlData
 * @param {FieldDefinitionsDto} fieldDefinitions
 * @return {*}
 */
function sanitizeUrlData(
    currentUrlData: Partial<SearchConfig>,
    fieldDefinitions: FieldDefinitionsDto,
    measureConfigs: MeasureConfigDto[],
    filters: FilterDto[],
) {
    // == 1. Check fields ==
    const urlData = structuredClone(currentUrlData);
    const existingFields = new Set(Object.keys(fieldDefinitions));

    if (urlData.gridColumns) {
        urlData.gridColumns = urlData.gridColumns?.filter((column) => existingFields.has(column));
    }
    if (urlData.gridColumnWidths) {
        urlData.gridColumnWidths = Object.fromEntries(Object.entries(urlData.gridColumnWidths).filter(([key]) => existingFields.has(key)));
    }
    if (urlData.gridOrderBy && !existingFields.has(urlData.gridOrderBy)) {
        delete urlData.gridOrderBy;
    }

    // == 2a. Check measureconfigids ==
    if (urlData.measureConfigDeskId && !measureConfigs.find((sel) => sel.id === urlData.measureConfigDeskId)) {
        urlData.measureConfigDeskId = measureConfigs[0].id;
    }

    const measureConfigGridIds = urlData.measureConfigGridIds?.filter((id) => measureConfigs.some((mc) => mc.id === id));
    if (urlData.measureConfigGridIds && measureConfigGridIds?.length === 0) {
        urlData.measureConfigGridIds = [measureConfigs[0].id];
    }

    // == 2b. Check scope attributes based on measure config ==
    if (urlData.scope?.attributes != null) {
        urlData.scope.attributes = pick(urlData.scope.attributes, ...existingFields);
    }

    // == 3. Check filter ==
    if (urlData.filterId !== undefined && !filters.find((sel) => sel.id === urlData.filterId)) {
        urlData.filterId = DEFAULT_FILTER_ID;
    }

    return urlData;
}

function useUrlSearchConfig({ onUrlDataReady, disabled }: UseUrlSearchConfigOptions) {
    // Guard to make sure that onUrlDataReady will be only triggered once after all requirements are satisfied
    const [hasTriggered, setHasTriggered] = useState(false);

    const { search, pathname } = useLocation();

    const fieldDefinitionsQuery = useMeasureFieldDefinitionsQuery(!hasTriggered);
    const measureConfigsQuery = useMeasureConfigsQuery(!hasTriggered);
    const filtersQuery = useFiltersQuery();

    const fieldDefinitions = fieldDefinitionsQuery.data;
    const measureConfigs = measureConfigsQuery.data;
    const filters = filtersQuery.data;

    useEffect(() => {
        // Do nothing if already run or disabled externally
        if (hasTriggered || disabled) {
            return;
        }

        if (fieldDefinitions === undefined || measureConfigs === undefined || filters === undefined) {
            // Not all data dependencies available yet for validations
            return;
        }

        // This may hold a set of temporary changes that should be initially be applied to the selected search config
        // Any further changes to the urlData will be ignored
        const urlData = parseUrl(search, pathname, fieldDefinitions, measureConfigs, filters);

        onUrlDataReady(urlData);

        // Do not trigger again after parsing URL once
        setHasTriggered(true);
    }, [hasTriggered, fieldDefinitions, measureConfigs, filters, search, pathname, onUrlDataReady, disabled]);
}

export default useUrlSearchConfig;
